import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import markdown from 'remark-parse';
import remark from 'remark';
import html from 'remark-html';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderStyles from 'react-awesome-slider/src/styled/fold-out-animation';

class ToNotForgetPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parsedHTML: "Oops, couldn't fetch list"
    };
  }
  componentDidMount() {
    const parsedHTML = remark()
      .use(html)
      .process(
        this.props.data.githubData.data.viewer.repository.object.text,
        (err, file) => {
          this.setState({
            parsedHTML: file
          });
        }
      );
  }
  render() {
    // const post = this.props.data.markdownRemark;
    const title = 'To Not Forget';
    const description = 'Just a Collection of Github Repos I want to remember';
    const siteTitle = this.props.data.site.siteMetadata.title;
    // console.log(this.props.data.githubData.data);

    // console.log(this.state.parsedHTML);
    // console.log(post);
    return (
      <Layout location={this.props.location} title={siteTitle + 'd'}>
        <SEO title={title} description={description} />
        <article className={`post-content ${`no-image`}`}>
          <header className="post-content-header">
            <h1 className="post-content-title">{title}</h1>
          </header>
          {description && <p className="post-content-excerpt">{description}</p>}
          {this.props.data.githubData && (
            <div
              className="post-content-body"
              dangerouslySetInnerHTML={{
                __html: this.state.parsedHTML
              }}
            />
          )}

          <hr />

          <footer className="post-content-footer">
            {/* There are two options for how we display the byline/author-info.
        If the post has more than one author, we load a specific template
        from includes/byline-multiple.hbs, otherwise, we just use the
        default byline. */}
          </footer>
        </article>
      </Layout>
    );
  }
}

export default ToNotForgetPage;

export const ToNotForgetQuery = graphql`
  query ToNotForgetQuery {
    site {
      siteMetadata {
        title
        author
      }
    }

  #  githubData {
  #    data {
  #      viewer {
  #        repository {
  #          object {
  #            text
  #          }
  #        }
  #      }
  #    }
  #  }
  }
`;
